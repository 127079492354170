import React, {Component} from "react";
import logo from "../images/logo/hishab_logo.png";
import {DataGrid} from '@mui/x-data-grid';
import {GridColDef} from "@mui/x-data-grid";
import {Box, Checkbox, Modal} from "@mui/material";
import {getFilePublicUrl, getSurveyList} from "../services/api";

class SurveyList extends Component {
    state = {
        audioList: [],
        audioListAll: [],
        isListenModalVisible: false,
        audioFileUrl: null,
        showOnlyUnhappySurveys: false
    };

    columns: GridColDef[] = [
        {field: 'callerNo', headerName: 'Caller No', flex: 3},
        {field: 'customerId', headerName: 'Customer Id', flex: 3},
        {field: 'serviceId', headerName: 'Service Id', flex: 1},
        {field: 'orgName', headerName: 'Organization', flex: 1, valueFormatter: params => params.value==='Null' ? '' : params.value},
        {field: 'rating', headerName: 'Rating', flex: 2},
        {field: 'correlationId', headerName: 'Correlation Id', flex: 2.5},
        {field: 'timestamp', headerName: 'Date Time', flex: 3, valueFormatter: params => new Date(params.value).toLocaleString('en-GB')},
        {field: 'fileDuration', headerName: 'Duration', flex: 1.5},
        {field: 'fileId', headerName: 'File', flex: 3, renderCell: params => this.renderFileCell(params) }
    ];

    async componentDidMount() {
        const surveys = await getSurveyList();
        this.setState(
            {
                ...this.state,
                audioList: surveys,
                audioListAll: surveys
            }
        );
    }

    renderFileCell(params) {
        return (
            <div onClick={() => this.openListenModal(params.value)}>
                <span style={{ color: 'blue' }}>{params.value}</span>
            </div>
        );
    }

    async openListenModal(fileName) {
        const publicUrl = await getFilePublicUrl(fileName);
        this.setState({
            ...this.state,
            isListenModalVisible: true,
            audioFileUrl: publicUrl
        })
    }

    closeListenModal() {
        this.setState({
            ...this.state,
            isListenModalVisible: false,
            audioFileUrl: null
        })
    }

    onFilterChange(checked) {
        this.setState({
            ...this.state,
            showOnlyUnhappySurveys: checked,
            audioList: checked ? this.state.audioListAll.filter(audio => audio['rating'] === 'UNHAPPY') : this.state.audioListAll
        });
    }

    render() {
        return (
            <div style={{ height: '500px'}}>
                <img src={logo} alt="Hishab Logo" className="rounded h-20 px-4 py-4"/>{" "}
                <br/>
                <p className="text-2xl font-family: ui-monospace italic px-16">
                    SURVEY HISTORY
                </p>
                <p style={{ marginLeft: '10px', marginTop: '20px'}}>
                    <Checkbox
                        checked={this.state.showOnlyUnhappySurveys}
                        onChange={(event,checked) => this.onFilterChange(checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    /> <span>Show Only Unhappy Users</span>
                </p>

                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1, padding: '20px' }}>
                        <DataGrid
                            disableSelectionOnClick
                            rows={this.state.audioList}
                            columns={this.columns}
                        />
                    </div>
                </div>

                <Modal
                    open={this.state.isListenModalVisible}
                    onClose={() => this.closeListenModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <audio controls>
                            <source src={this.state.audioFileUrl} />
                                Your browser does not support the audio element.
                        </audio>
                    </Box>
                </Modal>
            </div>
        );
    }
}

export default SurveyList;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};