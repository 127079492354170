import axios from "axios";

const baseUrl = 'https://surveyhistory.hishab.ai/api'

export async function getSurveyList() {
    const response = await axios.get(baseUrl + "/surveys/");
    console.log(response.data);
    return response.data;
}

export async function getFilePublicUrl(fileName) {
    const response = await axios.get(baseUrl + "/surveys/audio-url?fileName=" + fileName);
    console.log(response.data);
    return response.data;
}

